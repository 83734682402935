import React, { Component } from 'react';
import Helmet from 'react-helmet';
import { graphql } from 'gatsby';
import queryString from 'query-string';
import { get } from 'lodash';
import { Layout } from '../components/Layout';
import { SimplePageHero } from '../components/common';
import { FaqBody } from '../components/faq';
import { scrollToPageElement } from '../utils/helpers';

class FrequentlyAskedQuestions extends Component {
  componentDidMount() {
    const {
      location,
    } = this.props;
    if (location) {
      const queryParams = queryString.parse(location.search);
      const anchoredQuestionId = queryParams.question;
      if (anchoredQuestionId) scrollToPageElement(null, anchoredQuestionId);
    }
  }

  render() {
    const {
      data: {
        page: {
          data: pageData,
        },
      },
      location,
    } = this.props;
    const {
      pageName,
      heroSubtitle,
      body,
      metaTitle,
      metaDescription,
      openGraphImage,
    } = pageData;
    const seoData = {
      metaTitle,
      metaDescription,
      openGraphImage,
    };
    let categories = [];
    let allQuestions = [];
    body.forEach(((questionBlock) => {
      const { items } = questionBlock;
      const blockCategories = items.map(((faqItem) => {
        const categoryName = get(faqItem, 'category.document.data.categoryName.text', false);
        return categoryName ?? null;
      }));
      categories = [...categories, ...blockCategories];
      allQuestions = [...allQuestions, ...items];
    }));
    const uniqueCategories = [...new Set(categories)];
    const faqSchemaData = allQuestions && allQuestions.length > 0 && allQuestions.map((questionItem) => {
      const {
        question,
        answer,
      } = questionItem;
      return (
        {
          '@type': 'Question',
          name: question.text,
          acceptedAnswer: {
            '@type': 'Answer',
            text: answer.text,
          },
        }
      );
    });
    return (
      <Layout
        location={location}
        seoData={seoData}
        backgroundColor="quaternary"
      >
        <Helmet>
          <script
            className="structured-data-list"
            type="application/ld+json"
          >
            {JSON.stringify(faqSchemaData)}
          </script>
        </Helmet>
        <SimplePageHero
          headline={pageName.text}
          description={heroSubtitle.text}
          theme="quaternary"
        />
        <FaqBody
          uniqueCategories={uniqueCategories}
          allQuestions={allQuestions}
          questionBlocks={body}
          location={location}
        />
      </Layout>
    );
  }
}

export default FrequentlyAskedQuestions;

export const pageQuery = graphql`
query FrequentlyAskedQuestionsQuery {
  page: prismicFaq {
    uid
    data {
      pageName: page_name {
        text
      }
      heroSubtitle: hero_subtitle {
        text
      }
      body {
        ... on PrismicFaqBodyQuestionGroup {
          items {
            question {
              text
            }
            answer {
              html
            }
            category {
              document {
                ... on PrismicQuestionCategory {
                  id
                  data {
                    categoryName: category_name {
                      text
                    }
                  }
                }
              }
            }
          }
          primary {
            title {
              text
            }
            subtitle {
              text
            }
          }
        }
      }
      metaTitle: meta_title {
        html
        text
      }
      metaDescription: meta_description {
        html
        text
      }
      openGraphImage: open_graph_image {
        alt
        copyright
        url
      }
    }
  }
}
`;
