import React, { Component } from 'react';
import { Wrapper } from '../common';
import { ControlPanel, FaqBlock, SearchResults } from '.';
import './FaqBody.scss';

class FaqBody extends Component {
  state = {
    visibleQuestions: this.props.allQuestions, // eslint-disable-line
    activeCategory: null,
    searchQuery: '',
    searchActive: false,
  };

  setSearchHandler = (event) => {
    const { activeCategory } = this.state;
    const searchQuery = event.target.value.toLowerCase() || '';
    this.setState({ searchQuery });
    this.queryQuestions(activeCategory, searchQuery);
  }

  setCategoryHandler = (event, category) => {
    const { activeCategory, searchQuery } = this.state;
    if (event) event.preventDefault();
    if (category !== activeCategory) {
      this.setState({ activeCategory: category });
      this.queryQuestions(category, searchQuery);
    }
  }

  searchFocusHandler = (event, clicked) => {
    event.preventDefault();
    this.setState({ searchActive: clicked });
  }

  queryQuestions = (activeCategory, searchQuery) => {
    const { allQuestions } = this.props;
    let visibleQuestions = allQuestions;
    if (searchQuery) {
      visibleQuestions = allQuestions.filter(faqItem => (
        faqItem.question.text.toLowerCase().includes(searchQuery)
      ));
    }
    if (activeCategory) {
      visibleQuestions = visibleQuestions.filter((faqItem) => {
        const thisCategory = faqItem.category.document.data.categoryName.text;
        return activeCategory === thisCategory;
      });
    }
    this.setState({ visibleQuestions });
  }

  render() {
    const {
      searchActive,
      activeCategory,
      searchQuery,
      visibleQuestions,
    } = this.state;
    const {
      questionBlocks,
      uniqueCategories,
      location,
    } = this.props;
    return (
      <section className="faq-body">
        <Wrapper isBound>
          <ControlPanel
            activeCategory={activeCategory}
            uniqueCategories={uniqueCategories}
            searchActive={searchActive}
            searchQuery={searchQuery}
            setActiveCategoryHandler={this.setCategoryHandler}
            searchFocusHandler={this.searchFocusHandler}
            setSearchHandler={this.setSearchHandler}
            numResults={visibleQuestions.length}
          />
          {searchQuery !== '' || activeCategory ? (
            <SearchResults
              matchingQuestions={visibleQuestions}
              location={location}
            />
          ) : (
            <div className="faq-blocks">
              {questionBlocks && questionBlocks.length > 0 && questionBlocks.map((block, index) => (
                <FaqBlock
                  key={index}
                  block={block}
                  location={location}
                />
              ))}
            </div>
          )}
        </Wrapper>
      </section>
    );
  }
}

export default FaqBody;
