import React from 'react';
import { QuestionsList } from '.';
import './FaqBlock.scss';

const FaqBlock = (props) => {
  const {
    block,
    location,
  } = props;
  const {
    primary,
    items: questions,
  } = block;
  const {
    title,
    subtitle,
  } = primary;
  return (
    <div className="faq-block">
      <h2 className="block-title">{title.text}</h2>
      <span className="subtitle">{subtitle.text}</span>
      <QuestionsList
        questions={questions}
        location={location}
      />
    </div>
  );
};

export default FaqBlock;
