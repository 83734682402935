import React from 'react';

const CategoryButton = ({
  isActive,
  categoryText,
  setActiveCategoryHandler,
}) => (
  <button
    type="button"
    className={`category-button${isActive ? ' active' : ''}`}
    onClick={event => setActiveCategoryHandler(event, categoryText)}
    aria-label={isActive ? `Showing only questions related to ${categoryText}` : `Filter by ${categoryText} questions`}
  >
    <span>{categoryText}</span>
  </button>
);

export default CategoryButton;
