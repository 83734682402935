import React from 'react';
import './ResultsCounter.scss';

const ResultsCounter = ({
  hasValue,
  numResults,
  themeClass,
}) => (
  <div className={`result-counter${hasValue ? ' has-value' : ''}${themeClass ? ` ${themeClass}` : ''}`}>
    <span className="num-results">
      {`Found ${numResults} results`}
    </span>
  </div>
);

export default ResultsCounter;
