import React from 'react';
import { Wrapper } from '../common';
import './RevealButton.scss';

const RevealButton = (props) => {
  const {
    question,
    questionExpanded,
    setQuestionExpanded,
  } = props;
  return (
    <button
      type="button"
      className={`toggle-button${questionExpanded ? ` is-expanded` : ''}`}
      onClick={() => setQuestionExpanded(!questionExpanded)}
      aria-label={questionExpanded ? `Hide answer to "${question.text}"` : `See answer to "${question.text}"`}
    >
      <h2 className="the-question">
        {question.text}
      </h2>
      <div className="button">
        <div className={`icon ${questionExpanded ? 'toggled' : ''}`} />
      </div>
    </button>
  );
};

export default RevealButton;
