import React from 'react';
import {
  CategoryButtons,
  SearchBar,
  ResultsCounter,
} from '.';
import './ControlPanel.scss';

const ControlPanel = (props) => {
  const {
    activeCategory,
    uniqueCategories,
    setActiveCategoryHandler,
    searchActive,
    searchQuery,
    searchFocusHandler,
    setSearchHandler,
    numResults,
  } = props;
  return (
    <div className="control-panel">
      <SearchBar
        searchActive={searchActive}
        searchQuery={searchQuery}
        setSearchHandler={setSearchHandler}
        searchFocusHandler={searchFocusHandler}
      />
      <ResultsCounter
        numResults={numResults}
        hasValue={Boolean(searchQuery !== '')}
      />
      <CategoryButtons
        activeCategory={activeCategory}
        categories={uniqueCategories}
        setActiveCategoryHandler={setActiveCategoryHandler}
      />
    </div>
  );
};

export default ControlPanel;
