import React, { useState } from 'react';
import queryString from 'query-string';
import {
  RevealButton,
  QuestionContent,
} from '.';
import {
  toKebabCase,
  copyToClipboard,
} from '../../utils/helpers';
import './QuestionsListItem.scss';

const QuestionsListItem = (props) => {
  const {
    question,
    answer,
    location,
  } = props;
  const questionId = toKebabCase(question.text.replace(/[^\w\s]/gi, ''));
  const queryParams = queryString.parse(location.search);
  const anchoredQuestionId = queryParams.question;
  const [questionExpanded, setQuestionExpanded] = useState(questionId === anchoredQuestionId);
  const [showCopyNotification, setShowCopyNotification] = useState(false);
  const copyAnchorLink = (event, id) => {
    event.preventDefault();
    if (!showCopyNotification) {
      const {
        origin,
        pathname,
      } = location;
      const anchorUrl = `${origin}${pathname}?question=${id}`;
      copyToClipboard(anchorUrl);
      setShowCopyNotification(true);
      setTimeout(() => {
        setShowCopyNotification(false);
      }, 1500);
    }
  };
  return (
    <div
      id={questionId}
      className={`faq-question${questionExpanded ? ' expanded' : ''}`}
    >
      <div className="question-container">
        <RevealButton
          question={question}
          questionExpanded={questionExpanded}
          setQuestionExpanded={setQuestionExpanded}
        />
      </div>
      <QuestionContent
        questionExpanded={questionExpanded}
        answer={answer}
        question={question}
        questionId={questionId}
        showCopyNotification={showCopyNotification}
        copyAnchorLink={copyAnchorLink}
      />
    </div>
  );
};

export default QuestionsListItem;
