import React, { Component } from 'react';
import { QuestionsListItem } from '.';
import './QuestionsList.scss';

class QuestionsList extends Component {
  render() {
    const {
      questions,
      location,
    } = this.props;
    return (
      <section className="faq-question-list">
        <div className="funky-wrapper">
          <div className="questions">
            { questions && questions.length > 0 && (
              questions.map(faq => (
                <QuestionsListItem
                  {...faq}
                  location={location}
                />
              ))
            )}
          </div>
        </div>
      </section>
    );
  }
}

export default QuestionsList;
