import React from 'react';
import { QuestionsList } from '.';
import './SearchResults.scss';

const SearchResults = (props) => {
  const {
    matchingQuestions,
    location,
  } = props;
  return (
    <div className="search-results">
      { matchingQuestions && matchingQuestions.length > 0 ? (
        <QuestionsList
          questions={matchingQuestions}
          location={location}
        />
      ) : (
        <div className="no-results-message">
          <span>Sorry we couldn't find anything that meets that criteria</span>
        </div>
      )}
    </div>
  );
};

export default SearchResults;
