import React from 'react';
import AnimateHeight from 'react-animate-height';
import {
  HtmlContent,
} from '../common';
import {
  CopyLinkButton,
} from '.';
import './QuestionContent.scss';

const QuestionContent = (props) => {
  const {
    questionExpanded,
    answer,
    question,
    questionId,
    showCopyNotification,
    copyAnchorLink,
  } = props;
  return (
    <div className="question-content">
      <AnimateHeight
        duration={300}
        height={questionExpanded ? 'auto' : 0}
      >
        <div className="text-container">
          <HtmlContent content={answer.html} />
          <CopyLinkButton
            question={question}
            questionId={questionId}
            showCopyNotification={showCopyNotification}
            copyAnchorLink={copyAnchorLink}
          />
        </div>
      </AnimateHeight>
    </div>
  );
};

export default QuestionContent;
