import React from 'react';
import { CategoryButton } from '.';
import './CategoryButtons.scss';

const CategoryButtons = (props) => {
  const {
    activeCategory,
    categories,
    setActiveCategoryHandler,
  } = props;
  return (
    <section className="category-buttons">
      <span className="filter-label">Filter By</span>
      <div className="container">
        <div className="buttons">
          <button
            type="button"
            className={`category-button${activeCategory === null ? ' active' : ''}`}
            onClick={event => setActiveCategoryHandler(event, null)}
            aria-label={activeCategory === null ? 'Currently showing questions from all categories' : 'Show questions from all categories'}
            disabled={activeCategory === null}
          >
            <span>All</span>
          </button>
          {categories && categories.map(category => (
            <CategoryButton
              isActive={activeCategory === category}
              categoryText={category}
              setActiveCategoryHandler={setActiveCategoryHandler}
            />
          ))}
        </div>
      </div>
    </section>
  );
};

export default CategoryButtons;
